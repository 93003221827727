<!-- 我的余额 -->
<template>
  <div class="userBalance">
    <div class="body-box">
      <div class="my-box">
        <div class="title-box">
          <p v-text="$t('balance.my_balance')">我的余额</p>
        </div>
        <div class="money-text">
          <p>{{$t('common.$')}}{{balance}}</p>
          <div>
            <div class="click" @click.stop="toRechargePage" v-text="$t('balance.recharge')">充值</div>
            <p class="click" @click.stop="toCashoutPage" v-text="$t('balance.cashout')">提现</p>
          </div>
        </div>
      </div>
      <div class="money-title">
        <!-- <p class="click" @click.stop="type=1">余额明细</p> -->
        <div
          class="click"
          @click.stop="type=1"
          :class="{'selected':type==1}"
          v-text="$t('balance.balance')"
        >余额明细</div>
        <div
          class="click"
          @click.stop="type=2"
          :class="{'selected':type==2}"
          v-text="$t('balance.cashout_record')"
        >提现记录</div>
      </div>
      <!-- 余额明细 -->
      <div class="my-box" v-if="type==1">
        <div class="order-title">
          <p
            :class="detailType==1?'yes-choice':'no-choice'"
            @click.stop="detailType=1"
            v-text="$t('balance.all')"
          >全部</p>
          <p
            :class="detailType==2?'yes-choice':'no-choice'"
            @click.stop="detailType=2"
            v-text="$t('balance.recharge')"
          >充值</p>
          <p
            :class="detailType==3?'yes-choice':'no-choice'"
            @click.stop="detailType=3"
            v-text="$t('balance.pay')"
          >支付订单</p>
          <p
            :class="detailType==4?'yes-choice':'no-choice'"
            @click.stop="detailType=4"
            v-text="$t('balance.cashout')"
          >提现</p>
          <p
            :class="detailType==5?'yes-choice':'no-choice'"
            @click.stop="detailType=5"
            v-text="$t('balance.retreat')"
          >退款</p>
        </div>
        <!-- 余额明细 -->
        <div class="num-box" v-if="listData && listData.total>0">
          <div v-for="item in listData.data" :key="item.id">
            <h1 class="h1" v-text="item.update_time"></h1>
            <p v-text="item.type_text">充值</p>
            <p>{{item.change_type==1?'+':'-'}} {{common.showPrice(item.amount,item.price_type)}}</p>
          </div>
          <div class="pro-pagination-box">
            <el-pagination
              layout="prev, pager, next"
              :total="listData.total"
              :page-size="listData.per_page"
              :current-page="Number(listData.current_page)"
              background
              @current-change="pageChange"
            ></el-pagination>
          </div>
        </div>
        <div v-else class="empty">
          <!-- 如果没有余额明细显示以下图片 -->
          <img src="../../assets/icon/no-num.png" />
          <p>{{$t('balance.empty1')}}</p>
        </div>
      </div>
      <!-- 提现记录 -->
      <div class="my-box" v-if="type == 2">
        <div class="order-title">
          <p
            :class="detailType==1?'yes-choice':'no-choice'"
            @click.stop="detailType=1"
            v-text="$t('balance.all')"
          >全部</p>
          <p
            :class="detailType==2?'yes-choice':'no-choice'"
            @click.stop="detailType=2"
            v-text="$t('balance.yes')"
          >提现成功</p>
          <p
            :class="detailType==3?'yes-choice':'no-choice'"
            @click.stop="detailType=3"
            v-text="$t('balance.going')"
          >审核中</p>
          <p
            :class="detailType==4?'yes-choice':'no-choice'"
            @click.stop="detailType=4"
            v-text="$t('balance.no')"
          >提现失败</p>
        </div>
        <div class="record-box" v-if="listData && listData.total">
          <div class="record-text" v-for="item in listData.data" :key="item.id">
            <div>
              <h2 v-text="item.update_time">2018-10-23 12:21</h2>
              <p v-text="item.bank_name">工商银行</p>
              <p v-text="item.bank_item_name">重庆市渝中区全境 大溪沟51号 9-1</p>
              <p v-if="item.disagree_reason" class="yuanyin">原因:{{item.disagree_reason}}</p>
            </div>
            <h2>
              <span v-text="$t('balance.cashout_money')">提现金额：</span>
              {{common.showPrice(item.amount,item.price_type)}}
            </h2>
            <p class="red-text" v-text="item.status_text" v-if="item.status==2"></p>
            <p class="blue-text" v-text="item.status_text" v-else>审核中</p>
          </div>
          <div class="pro-pagination-box">
            <el-pagination
              layout="prev, pager, next"
              :total="listData.total"
              :page-size="listData.per_page"
              :current-page="Number(listData.current_page)"
              background
              @current-change="pageChange"
            ></el-pagination>
          </div>
        </div>
        <!-- 如果没有余额明细显示以下图片 -->
        <div v-else class="empty">
          <!-- 如果没有余额明细显示以下图片 -->
          <img src="../../assets/icon/no-num.png" />
          <p>{{$t('balance.empty2')}}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "UserBalance",
  data() {
    return {
      type: 1, //1余额明细 2提现记录
      detailType: 1, // 1全部 2充值 3支付订单 4提现 5退款
      empty: false,
      balance: 0,
      listData: null,
      userinfo: this.common.getUserInfo()
    };
  },
  created() {
    this.httpMyBalance();
    // 请求我的余额明细
    this.myBalanceDetailList();
  },
  watch: {
    detailType() {
      this.listData = null;
      if (this.type == 1) {
        this.myBalanceDetailList();
      } else if (this.type == 2) {
        this.myWithdrawList();
      }
    },
    type() {
      this.listData = null;
      this.detailType = 1;
      if (this.type == 1) {
        this.myBalanceDetailList();
      } else if (this.type == 2) {
        this.myWithdrawList();
      }
    }
  },
  methods: {
    /**
     * 请求我的月明细
     */
    myBalanceDetailList(page) {
      var self = this;
      if (!page)
        page = self.listData ? Number(self.listData.current_page) + 1 : 1;
      var filter_type = "";
      if (this.detailType > 1)
        filter_type = "&filter_type=" + (this.detailType - 1);
      self.common
        .httpPost(
          "/api/user/myBalanceDetailList?page=" + page + filter_type,
          {}
        )
        .then(ret => {
          if (ret.code == 1) {
            self.listData = ret.data;
          }
        });
    },
    myWithdrawList(page) {
      var self = this;
      if (!page)
        page = self.listData ? Number(self.listData.current_page) + 1 : 1;
      var filter_status = "";
      if (this.detailType == 2) filter_status = "&filter_status=1";
      if (this.detailType == 3) filter_status = "&filter_status=0";
      if (this.detailType == 4) filter_status = "&filter_status=2";

      self.common
        .httpPost("/api/user/myWithdrawList?page=" + page + filter_status, {})
        .then(ret => {
          if (ret.code == 1) {
            self.listData = ret.data;
          }
        });
    },
    pageChange(page) {
      if (this.type == 1) this.myBalanceDetailList(page);
      if (this.type == 2) this.myWithdrawList(page);
    },
    toRechargePage() {
      this.$router.push("/user/recharge");
    },
    toCashoutPage() {
      this.$router.push("/user/cashout");
    },
    /**
     * 请求我的余额
     */
    httpMyBalance() {
      var self = this;
      this.common.httpPost("/api/user/getMyBalance", {}).then(ret => {
        if (ret.code == 1) {
          self.balance = ret.data;
        }
      });
    }
  },
  components: {}
};
</script>
<style scoped>
.page {
  margin: 20px 83px 44px 83px;
  display: flex;
}

.left-box {
  width: 196px;
  max-height: 1000px;
  min-height: 550px;
}

.body-box {
  width: 982px;
}

.my-box {
  border: 1px solid rgba(182, 182, 182, 0.7);
  margin-bottom: 30px;
}

.title-box {
  display: flex;
  align-items: center;
  height: 50px;
  background: rgba(247, 246, 246, 1);
}

.title-box p {
  font-size: 16px;
  font-weight: 500;
  color: rgba(153, 153, 153, 1);
  line-height: 50px;
  margin-left: 30px;
}

.money-text {
  padding-left: 30px;
  padding-right: 30px;
}

.money-text > p {
  margin-top: 36px;
  margin-bottom: 15px;
  font-size: 28px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 28px;
}

.pro-pagination-box {
  display: flex;
  justify-content: center;
  margin-top: 25px !important;
}

.money-text > div {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.money-text div div {
  width: 60px;
  height: 26px;
  background: rgba(41, 127, 171, 1);
  border-radius: 2px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  line-height: 26px;
  text-align: center;
  margin-right: 20px;
}

.money-text div p {
  font-size: 16px;
  font-weight: 400;
  color: rgba(41, 127, 171, 1);
  line-height: 16px;
}

.money-title {
  padding-top: 5px;
  padding-bottom: 12px;
  display: flex;
  align-items: center;
}

.money-title p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(41, 127, 171, 1);
  line-height: 14px;
  margin-right: 10px;
}

.money-title div {
  font-size: 14px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  line-height: 14px;
  padding-right: 10px;
}

.money-title div.selected {
  color: rgba(41, 127, 171, 1);
}

.money-title div:last-child {
  padding-left: 10px;
  border-left: 1px solid rgba(153, 153, 153, 0.7);
}

.order-title {
  display: flex;
  align-items: center;
  height: 50px;
  background: rgba(247, 246, 246, 1);
}

.order-title p {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  padding-left: 30px;
  padding-right: 30px;
  cursor: pointer;
}

.yes-choice {
  color: rgba(41, 127, 171, 1);
}

.no-choice {
  color: rgba(153, 153, 153, 1);
}

.num-box > div {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.num-box {
  padding-left: 30px;
  padding-right: 30px;
}

.num-box div {
  height: 49px;
  align-items: center;
  border-bottom: 0.4px solid rgba(182, 182, 182, 0.9);
  margin: 0;
}

.num-box div:last-child {
  border-bottom: 0;
}

h1 {
  width: 220px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  line-height: 14px;
}

.h1 span {
  margin-left: 5px;
}

.h1 {
  padding-bottom: 0px !important;
}

.num-box div p {
  width: 250px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 14px;
  text-align: center;
}

.no-num {
  height: 250px;
  width: 250px;
  text-align: center;
  margin: 30px 366px;
}

.record-box {
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 25px;
}

.record-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px dashed 1px solid rgba(182, 182, 182, 0.7);
}

.record-text:last-child {
  border-bottom: 0;
}

.record-text > div {
  min-width: 350px;
}

h2 {
  font-size: 14px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 14px;
}

.record-text > div p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  line-height: 14px;
  margin-top: 10px;
  max-width: 300px;
  /* line-height: 16px; */
}

.record-text > div p:first-child {
  margin-top: 15px;
}

.record-text > div p.yuanyin {
  line-height: 16px;
}

.record-text > p {
  width: 204px;
  text-align: center;
}

.blue-text {
  font-size: 14px;
  font-weight: 400;
  color: rgba(41, 127, 171, 1);
  line-height: 14px;
}

.red-text {
  font-size: 14px;
  font-weight: 400;
  color: rgba(225, 36, 27, 1);
  line-height: 14px;
}
</style>